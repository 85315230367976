<template>
	<div class="card card-stretch gutter-b d-flex flex-column align-items-stretch">
		<div class="card-header border-0 d-flex justify-content-between align-items-center py-2 px-4">
			<h3 class="card-title font-weight-bolder text-dark mb-0">{{ device.Name }} - Realtime data</h3>
			<div class="col-3 d-flex align-items-center justify-content-between"></div>
			<div class="card-toolbar">
				<span class="float-right"
					>Get Past:
					<b-form-select class="bform" v-model="getpastval" @change="getPast" :options="getPastOptions"></b-form-select>
				</span>
			</div>
		</div>
		<div class=" pt-2">
			<b-tabs content-class="mt-3" fill card>
				<b-tab title="General">
					<RealtimeGeneral :device="device" :general="general" :generalstats="generalstats" />
				</b-tab>
				<b-tab title="Events/Conditions" active lazy>
					<RealtimeEvents :id="id" :device="device" />
				</b-tab>
				<b-tab title="SATCOM" lazy><RealtimeSatCom :data="data" :satcom="satcom" :graphs="graphs" :key="graphs.rem.data.length"/></b-tab>
				<b-tab title="SAT Traffic" lazy
					><RealtimeChart v-if="graphs.sat.data && graphs.sat.data.length > 0" :data="graphs.sat" :key="graphs.sat.data.length"
				/></b-tab>
				<b-tab title="IP Traffic" lazy
					><RealtimeChart v-if="graphs.ip.data && graphs.ip.data.length > 0" :data="graphs.ip" :key="graphs.ip.data.length"
				/></b-tab>
				<b-tab title="Remote Status" lazy>
					<RealtimeChart v-if="graphs.rem.data && graphs.rem.data.length > 0" :data="graphs.rem" :key="graphs.rem.data.length" />
				</b-tab>
				<b-tab title="UCP Info" lazy>
					<RealtimeChart v-if="graphs.snr.data && graphs.snr.data.length > 0" :data="graphs.snr" :key="graphs.snr.data.length"
				/></b-tab>
				<b-tab title="Latency" lazy>
					<RealtimeChart v-if="graphs.rtt.data && graphs.rtt.data.length > 0" :data="graphs.rtt" :key="graphs.rtt.data.length"
				/></b-tab>
				<b-tab title="Map" lazy>
					<RealtimeMap :device="this.device" />
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>
import { MODCODS } from '@/helpers';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

const date = new Date();
const offset = date.getTimezoneOffset() * 60000;

var modcodlist = MODCODS.filter(n => {
	if (typeof n.name === 'undefined') {
		return null;
	}
	return n;
}).map((n, i) => {
	return { v: i, label: n.name };
});

export default {
	name: 'Realtime',
	props: ['id'],
	components: {
		RealtimeGeneral: () => import('@/view/content/widgets/realtime/RealtimeGeneral.vue'),
		RealtimeChart: () => import('@/view/content/widgets/realtime/RealtimeChart.vue'),
		RealtimeSatCom: () => import('@/view/content/widgets/realtime/RealtimeSatCom.vue'),
		RealtimeEvents: () => import('@/view/content/widgets/realtime/RealtimeEvents.vue'),
		RealtimeMap: () => import('@/view/content/widgets/realtime/RealtimeMap.vue'),
	},

	data() {
		return {
			device: {},
			general: {},
			getpastval: 0,
			getPastOptions: [
				{ value: '0', text: '' },
				{ value: '900', text: '15 minutes' },
				{ value: '3600', text: '1 hour' },
				{ value: '21600', text: '6 hours' },
				{ value: '43200', text: '12 hours' },
			],
			graphVis: {
				sat: [false, false, false, true, false, false, false, true, true],
				ip: [false, false, false, false, false, false, true, false, false, false, false, false, false, true],
			},
			data: {
				ip: [],
				ota: [],
				remote: [],
				rtt: [],
				ucp: [],
			},
			graphs: {
				sat: {
					tabname: 'SAT Traffic',
					active: true,
					countdown: 0,
					countdownInt: null,
					intervals: null,
					label: 'sat',
					datasrc: 'ota',
					theads: [
						'Timestamp',
						'Up Reliable',
						'Up Unreliable',
						'Up OOB',
						'Up Totals',
						'Down Reliable',
						'Down Unreliable',
						'Down OOB',
						'Down Totals',
						'MODCOD',
					],
					fields: [
						{ key: 'timestamp', label: 'Timestamp' },
						{ key: 'rx_reliable', label: 'Up Reliable' },
						{ key: 'rx_unreliable', label: 'Up Unreliable' },
						{ key: 'rx_oob', label: 'Up OOB' },
						{ key: 'rx_total', label: 'Up Totals' },
						{ key: 'tx_reliable', label: 'Down Reliable' },
						{ key: 'tx_unreliable', label: 'Down Unreliable' },
						{ key: 'tx_oob', label: 'Down OOB' },
						{ key: 'tx_total', label: 'Down Totals' },
						{ key: 'current_mc_idx', label: 'MODCOD' },
					],
					gfields: ['rx_reliable', 'rx_unreliable', 'rx_oob', 'rx_total', 'tx_reliable', 'tx_unreliable', 'tx_oob', 'tx_total', 'current_mc_idx'],
					opts: {
						legend: 'always',
						labels: [
							'Timestamp',
							'Up Reliable',
							'Up Unreliable',
							'Up OOB',
							'Up Totals',
							'Down Reliable',
							'Down Unreliable',
							'Down OOB',
							'Down Totals',
							'MODCOD',
						],
						labelsKMB: true,
						series: {
							MODCOD: {
								axis: 'y2',
							},
						},
						axisLabelFontSize: 9,
						axes: {
							y: {
								valueFormatter: function(i) {
									var val = i;
									if (i > 1000000000) val = Math.round((i / 1000000000) * 100) / 100 + 'G';
									else if (i > 1000000) val = Math.round((i / 1000000) * 100) / 100 + 'M';
									else if (i > 1000) val = Math.round((i / 1000) * 100) / 100 + 'k';
									return val + 'bps';
								},
							},
							y2: {
								ticker: function(min, max, pixels, opts, dygraph, vals) {
									return modcodlist;
								},
								valueFormatter: function(i) {
									if (i < 24) {
										return MODCODS[i].name;
									}
									return null;
								},
							},
						},
					},
					data: [],
				},

				ip: {
					tabname: 'IP Traffic',
					label: 'ip',
					datasrc: 'ip',
					countdown: 0,
					countdownInt: null,
					intervals: null,
					theads: [
						'Timestamp',
						'Up TCP',
						'Up UDP',
						'Up ICMP',
						'Up IGMP',
						'Up HTTP',
						'Up Other',
						'Up Total',
						'Down TCP',
						'Down UDP',
						'Down ICMP',
						'Down IGMP',
						'Down HTTP',
						'Down Other',
						'Down Total',
					],
					fields: [
						{ key: 'timestamp', label: 'Timestamp', sortable: true, sortDirection: 'desc' },
						{ key: 'rx_tcp', label: 'RX TCP' },
						{ key: 'rx_udp', label: 'RX UDP' },
						{ key: 'rx_icmp', label: 'RX ICMP' },
						{ key: 'rx_igmp', label: 'RX IGMP' },
						{ key: 'rx_http', label: 'RX HTTP' },
						{ key: 'rx_other', label: 'RX Other' },
						{ key: 'rx_total', label: 'Rx Total' },
						{ key: 'tx_tcp', label: 'TX TCP' },
						{ key: 'tx_udp', label: 'TX UDP' },
						{ key: 'tx_icmp', label: 'TX ICMP' },
						{ key: 'tx_igmp', label: 'TX IGMP' },
						{ key: 'tx_http', label: 'TX HTTP' },
						{ key: 'tx_other', label: 'TX Other' },
						{ key: 'tx_total', label: 'TX Total' },
					],
					gfields: [
						'rx_tcp',
						'rx_udp',
						'rx_icmp',
						'rx_igmp',
						'rx_http',
						'rx_other',
						'rx_total',
						'tx_tcp',
						'tx_udp',
						'tx_icmp',
						'tx_igmp',
						'tx_http',
						'tx_other',
						'tx_total',
					],
					opts: {
						legend: 'always',
						labels: [
							'Timestamp',
							'Up TCP',
							'Up UDP',
							'Up ICMP',
							'Up IGMP',
							'Up HTTP',
							'Up Other',
							'Up Total',
							'Down TCP',
							'Down UDP',
							'Down ICMP',
							'Down IGMP',
							'Down HTTP',
							'Down Other',
							'Down Total',
						],
						labelsKMB: true,
						axisLabelFontSize: 9,
						axes: {
							y: {
								valueFormatter: function(i) {
									var val = i;
									if (i > 1000000000) val = Math.round((i / 1000000000) * 100) / 100 + 'G';
									else if (i > 1000000) val = Math.round((i / 1000000) * 100) / 100 + 'M';
									else if (i > 1000) val = Math.round((i / 1000) * 100) / 100 + 'k';
									return val + 'bps';
								},
							},
						},
					},
					data: [],
				},

				rem: {
					tabname: 'Remote Status',
					label: 'rem',
					datasrc: 'remote',
					countdown: 0,
					countdownInt: null,
					intervals: null,

					theads: [
						'Timestamp',
						'Down C/N[dB]',
						'Tx Pwr[dBm]',
						'Clock DAC',
						'Rx COF[Hz]',
						'Temp[C]',
						'Time Ticks',
						'Rx Comp Power',
						'FO Offset',
						'CRC8',
						'CRC32',
						'NCR Lost',
						'PL Sync Lost',
						'Clock Delta',
						'Raw AGC',
					],
					fields: [
						'timestamp',
						'snr_cal',
						'power_in_dbm',
						'fll_dac',
						'rx_cof',
						'temperature_celcius',
						'time_tics',
						'digital_rx_power',
						'local_fo_correction',
						'dvb_s2_crc8_error',
						'dvb_s2_crc32_error',
						'lostlock_count',
						'lost_pl_lock_count',
						'clock_delta_count',
						'dvb_s2_raw_agc',
					],
					gfields: ['snr_cal'],
					opts: {
						legend: 'always',
						fillGraph: true,
						labels: ['Timestamp', 'C/N'],
						axisLabelFontSize: 9,
						axes: {
							y: {
								valueFormatter: function(i) {
									return i + ' dB';
								},
							},
						},
					},
					data: [],
				},

				snr: {
					tabname: 'UCP Info',
					label: 'snr',
					datasrc: 'ucp',
					countdown: 0,
					countdownInt: null,
					intervals: null,
					theads: ['Timestamp', 'Up C/N[dB]', 'Power Adjustment[dBm]', 'Symbol Offset', 'Freq Offset[Hz]'],
					fields: ['timestamp', 'snr_cal', 'power_adjustment', 'sym_offset', 'freq_offset'],
					gfields: ['snr_cal'],
					opts: {
						legend: 'always',
						fillGraph: true,
						labels: ['Timestamp', 'C/N'],
						axisLabelFontSize: 9,
						axes: {
							y: {
								valueFormatter: function(i) {
									return i + ' dB';
								},
							},
						},
					},
					data: [],
				},

				rtt: {
					tabname: 'Latency',
					label: 'rtt',
					datasrc: 'rtt',
					countdown: 0,
					countdownInt: null,
					intervals: null,
					theads: ['Timestamp', 'RTT'],
					fields: ['timestamp', 'rtt'],
					gfields: ['rtt'],
					opts: {
						legend: 'always',
						fillGraph: true,
						labels: ['Timestamp', 'RTT'],

						axisLabelFontSize: 9,
					},
					data: [],
				},
			},

			satcom: {
				downcn: {
					opts: {
						legend: 'always',
						labels: ['Timestamp', 'C/N'],

						axisLabelFontSize: 9,
						ylabel: 'C/N',
						height: 150,
					},
					data: [],
				},
				ncrlost: {
					opts: {
						legend: 'always',
						labels: ['Timestamp', 'NCR Lost'],

						axisLabelFontSize: 9,
						ylabel: 'NCR Lost',
						height: 150,
					},
					data: [],
				},
				upcn: {
					opts: {
						legend: 'always',
						labels: ['Timestamp', 'C/N'],

						axisLabelFontSize: 9,
						ylabel: 'C/N',
						height: 150,
					},
					data: [],
				},
				txpower: {
					opts: {
						legend: 'always',
						labels: ['Timestamp', 'Tx Power'],

						axisLabelFontSize: 9,
						ylabel: 'Tx Power',
						height: 150,
					},
					data: [],
				},
				symoff: {
					opts: {
						legend: 'always',
						labels: ['Timestamp', 'Sym Offset'],

						axisLabelFontSize: 9,
						ylabel: 'Sym Offset',
						height: 150,
					},
					data: [],
				},
				freqoff: {
					opts: {
						legend: 'always',
						labels: ['Timestamp', 'Freq Off'],

						axisLabelFontSize: 9,
						ylabel: 'Freq Off',
						height: 150,
					},
					data: [],
				},
			},
			showoptionfile: false,
		};
	},
	computed: {
		generalstats() {
			let stats = {
				loaded: false,
				tempHigher: 0,
				snrHigher: 0,
				upSnrHigher: 0,
				powerHigher: 0,
				downsnr: null,
				upsnr: null,
				txPower: null,
				tempC: null,
				showGeneralRealtime: false,
				remoteTimestamp: '',
				ucpTimestamp: '',
			};
			if (this.data.remote.length == 0) {
				return stats;
			} else {
				stats.loaded = true;
				var last = this.data.remote[this.data.remote.length - 1];
				var seclast = this.data.remote[this.data.remote.length - 2];
				if (this.timeIsAfter(this.data.remote[this.data.remote.length - 1].timestamp, 30)) {
					stats.showGeneralRealtime = true;
				}
				stats.remoteTimestamp = last.timestamp;
				stats.downsnr = last.snr_cal;
				stats.txPower = last.power_in_dbm;
				stats.tempC = last.temperature_celcius;

				if (last.temperature_celcius > seclast.temperature_celcius) {
					stats.tempHigher = 1;
				}
				if (last.temperature_celcius < seclast.temperature_celcius) {
					stats.tempHigher = -1;
				}
				if (last.snr_cal > seclast.snr_cal) {
					stats.snrHigher = 1;
				}
				if (last.snr_cal < seclast.snr_cal) {
					stats.snrHigher = -1;
				}
				if (last.power_in_dbm > seclast.power_in_dbm) {
					stats.powerHigher = 1;
				}
				if (last.power_in_dbm < seclast.power_in_dbm) {
					stats.powerHigher = -1;
				}
				if (this.data.ucp.length > 0) {
					stats.ucpTimestamp = this.data.ucp[this.data.ucp.length - 1].timestamp;
					stats.upsnr = this.data.ucp[this.data.ucp.length - 1].snr_cal;
					if (this.data.ucp[this.data.ucp.length - 1].snr_cal < this.data.ucp[this.data.ucp.length - 2].snr_cal) {
						stats.upSnrHigher = 1;
					}
					if (this.data.ucp[this.data.ucp.length - 1].snr_cal > this.data.ucp[this.data.ucp.length - 2].snr_cal) {
						stats.upSnrHigher = -1;
					}
				}
				return stats;
			}
		},
	},
	methods: {
		startTimers(i, interval) {
			this.graphs[i].countdown = interval;
			clearInterval(this.graphs[i].countdownInt);
			this.graphs[i].countdownInt = setInterval(() => {
				this.graphs[i].countdown--;
				if (this.graphs[i].countdown < 0) {
					this.graphs[i].countdown = interval;
				}
			}, 1000);
		},
		getRF() {
			this.$http.get(`idirect/modemrf/${this.id}`).then(modemResp => {
				this.general = modemResp.data.data;
				this.general.rfloaded = true;
			});
		},
		getDevice() {
			this.$http.get(`device/${this.id}`).then(resp => {
				this.device = resp.data.data;
				if (parseInt(this.device.version.substring(0, 2)) > 13) {
					this.graphs.snr.theads[1] = 'UPCN0';
				}
			});
		},
		timeIsAfter(timestamp, mins) {
			if (typeof timestamp !== 'undefined') {
				var ago = new Date().getTime() + offset - mins * 60000;
				var ts = new Date(timestamp).getTime() + offset;
				if (ts > ago) {
					return true;
				}
				return false;
			}
			return false;
		},
		getPast() {
			if (this.getpastval != 0) {
				Object.keys(this.graphs).forEach(i => {
					var n = this.graphs[i];
					this.$http.get(`realtime/${this.id}/${n.datasrc}?sec=${this.getpastval}`).then(data => {
						this.graphs[i].data = [];
						this.data[n.datasrc] = JSON.parse(JSON.stringify(data.data.data));
						this.graphs[i].rawdata = this.data[n.datasrc];

						this.satcom.ncrlost.data = [];
						this.satcom.txpower.data = [];
						this.satcom.symoff.data = [];
						this.satcom.freqoff.data = [];
						for (var z = 0; z < this.data[n.datasrc].length; z++) {
							var p = data.data.data[z];
							if (typeof p !== 'undefined' && p != 'no data') {
								var row = [new Date(Date.parse(p.timestamp.replace(/-/g, '/')))];
								n.gfields.forEach(o => {
									row.push(p[o]);
								});
								this.graphs[i].data.push(row);
								if (n.datasrc == 'remote') {
									this.satcom.ncrlost.data.push([new Date(Date.parse(p['timestamp'].replace(/-/g, '/'))), p['lostlock_count']]);
									this.satcom.txpower.data.push([new Date(Date.parse(p['timestamp'].replace(/-/g, '/'))), p['power_in_dbm']]);
								}
								if (n.datasrc == 'ucp') {
									this.satcom.symoff.data.push([new Date(Date.parse(p['timestamp'].replace(/-/g, '/'))), p['sym_offset']]);
									this.satcom.freqoff.data.push([new Date(Date.parse(p['timestamp'].replace(/-/g, '/'))), p['freq_offset']]);
								}
							}
						}
					});
				});
			}
		},
	},
	created() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Realtime' }]);
		this.graphs.ip.opts.visibility = this.graphVis['ip'];
		this.graphs.sat.opts.visibility = this.graphVis['sat'];
		this.rfloaded = false;
		this.getRF();
		this.getDevice();

		// load initial graph data
		Object.keys(this.graphs).forEach(i => {
			var n = this.graphs[i];
			this.$http.get(`realtime/${this.id}/${n.datasrc}?sec=300`).then(data => {
				//
				this.graphs[i].data = [];
				this.data[n.datasrc] = JSON.parse(JSON.stringify(data.data.data));
				this.graphs[i].rawdata = this.data[n.datasrc];

				for (var z = 0; z < this.data[n.datasrc].length; z++) {
					var p = data.data.data[z];
					if (typeof p !== 'undefined' && p != 'no data') {
						var row = [new Date(Date.parse(p.timestamp.replace(/-/g, '/')))];
						n.gfields.forEach(o => {
							row.push(p[o]);
						});
						this.graphs[i].data.push(row);

						if (n.datasrc == 'remote') {
							this.satcom.ncrlost.data.push([new Date(Date.parse(p['timestamp'].replace(/-/g, '/'))), p['lostlock_count']]);
							this.satcom.txpower.data.push([new Date(Date.parse(p['timestamp'].replace(/-/g, '/'))), p['power_in_dbm']]);
						}
						if (n.datasrc == 'ucp') {
							this.satcom.symoff.data.push([new Date(Date.parse(p['timestamp'].replace(/-/g, '/'))), p['sym_offset']]);
							this.satcom.freqoff.data.push([new Date(Date.parse(p['timestamp'].replace(/-/g, '/'))), p['freq_offset']]);
						}
					}
				}

				// setup intervals
				var interval = 20;
				if (this.data[n.datasrc] && this.data[n.datasrc][0] && this.data[n.datasrc][0].t_interval && this.data[n.datasrc][0].t_interval > 0) {
					interval = this.data[n.datasrc][0].t_interval;
				}
				if (interval < 30) {
					interval = 30;
				}

				this.graphs[i].intervals = setInterval(() => {
					this.$http.get(`realtime/${this.id}/${n.datasrc}`).then(d => {
						if (d.data && d.data.data.timestamp) {
							// update table
							this.graphs[i].rawdata.push(d.data.data);
							// update graph data and don't push older dates that the last entry
							if (new Date(Date.parse(d.data.data.timestamp.replace(/-/g, '/'))) > this.graphs[i].data[this.graphs[i].data.length - 1][0]) {
								var row = [new Date(Date.parse(d.data.data.timestamp.replace(/-/g, '/')))];
								n.gfields.forEach(o => {
									row.push(d.data.data[o]);
								});
								this.graphs[i].data.push(row);
							}
						}
						this.startTimers(i, interval);
					});
				}, interval * 1000);
				this.startTimers(i, interval);
			});
		});
	},
	beforeDestroy() {
		Object.keys(this.graphs).forEach(i => {
			clearInterval(this.graphs[i].intervals);
			clearInterval(this.graphs[i].countdownInt);
		});
	},
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
